import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { AppComponent } from './app.component';
import { VisualizadorComponent } from './components/visualizador/visualizador.component';
import { HomeComponent } from './components/home/home.component';
import { ROUTING } from './app.routing';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    VisualizadorComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    ColorPickerModule,
    FormsModule,
    ROUTING
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
