import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core/src/metadata/ng_module';
import { VisualizadorComponent } from './components/visualizador/visualizador.component';
import { HomeComponent } from './components/home/home.component';

 
export const AppRoutes: Routes = [
    { path: 'ver', component: VisualizadorComponent },
    { path: '', component: HomeComponent }
];
 
export const ROUTING: ModuleWithProviders = RouterModule.forRoot(AppRoutes);