import { Component, OnInit } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private color: string = "#FFF";
  private colorText: string = "#000";
  hide:boolean= false;
  fuenteSelected:any= '1';
  texto:any=[{text:"Test text",size:6}]
  constructor()
  {
  
  }

  coloresP:any =[
    {nombre:"rojo",color:"#F11142"},
    {nombre:"naranja",color:"#F68744"},
    {nombre:"amarillo",color:"#FBDB8B"},
    {nombre:"verde",color:"#27C08A"},
    {nombre:"azul",color:"#7A9AE9"},
    {nombre:"azul marino",color:"#212E51"},
    {nombre:"negro",color:"#000"},
    {nombre:"blanco",color:"#FFF"},
  ]
fonts :any= [
'Antic Slab, serif',
'IM Fell French Canon SC, serif',
'Marcellus SC, serif',
'Copse, serif',
'Cutive, serif',
'Kurale, serif',
'Andada, serif',
'Trocchi, serif',
'Cormorant SC, serif',
'Holtwood One SC, serif',
'IM Fell English, serif',
'Mate, serif',
'IM Fell English SC, serif',
'Maitree, serif',
'Gabriela, serif',
'Righteous, cursive',
'Concert One, cursive',
'Diplomata SC, cursive',
'Passion One, cursive',
'Lobster Two, cursive',
'Fredoka One, cursive',
'Monoton, cursive',
'Poiret One, cursive',
'Bangers, cursive',
'Luckiest Guy, cursive',
'Mirza, cursive',
'Alfa Slab One, cursive',
'Audiowide, cursive',
'Unica One, cursive',
'Nova Script, cursive',
'Oleo Script, cursive',
'Chango, cursive',
'Cabin Sketch, cursive',
'Special Elite, cursive',
'Chewy, cursive',
'Fugaz One, cursive',
'Bevan, cursive',
'Sigmar One, cursive',
'Hanalei Fill, cursive',
'Love Ya Like A Sister, cursive',
'Cinzel Decorative, cursive',
'Bungee Inline, cursive',
'Faster One, cursive',
'Vast Shadow, cursive',
'Frijole, cursive',
'Shojumaru, cursive',
'Oregano, cursive',
'Seaweed Script, cursive',
'Rye, cursive',
'Baloo Bhaina, cursive',
'Galindo, cursive',
'Lily Script One, cursive',
'Finger Paint, cursive',
'Creepster, cursive',
'Bungee Shade, cursive',
'Galada, cursive',
'Nosifer, cursive',
'Kranky, cursive',
'Mogra, cursive',
'Pirata One, cursive',
'Maiden Orange, cursive',
'Ribeye, cursive',
'Overlock SC, cursive',
'Buda, cursive',
'Trade Winds, cursive',
'Baloo Thambi, cursive',
'MedievalSharp, cursive',
'Croissant One, cursive',
'Zilla Slab Highlight, cursive',
'Ewert, cursive',
'Modak, cursive',
'Bungee Outline, cursive',
'Gloria Hallelujah',
'Dancing Script',
'Shadows Into Light',
'Yanone Kaffeesatz',
'Uncial Antiqua',
'Bree Serif',
'Acme',
'Comfortaa',
'Amatic SC',
'Baloo Bhai',
'Righteous',
'Permanent Marker',
'Cinzel',
'Alegreya',
'Satisfy',
'Kaushan Script',
'Courgette',
'Poiret One',
'Lobster Two',
'Khand']
  ngOnInit() {
  }
  duplicarUltimo(){
    let textito = JSON.parse(JSON.stringify(this.texto[this.texto.length-1]));
    this.texto.push(textito)
  }
  eliminar1(id){
    console.log("eliminar",id)
    this.texto.splice(id,1)
  }
  sizes(op,step){
    this.texto.forEach(t => {
        if(op=='+')
          t.size+= step;
        else
          t.size-= step;
    });
  }
  minusFont(){
    if(this.fuenteSelected>=0)
    this.fuenteSelected--
  }

  plusFont(){
    if(this.fuenteSelected<this.fonts.length)
    this.fuenteSelected++
  }
}
